import React, { Component } from "react"
import styled from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Translate } from "gatsby-plugin-translate"
import LanguageSwitcher from "./languageSwitcher"
import { Link } from "gatsby"
import { navigate } from 'gatsby'

const NavigationWrapper = styled.div`
  @media (max-width: 1023px) {
    display: none;
  }
`

const NavigationList = styled.ul`
  display: flex;
  list-style: none;
  padding-left: 0;
  margin-left: 0;
`

const NavigationListItem = styled.li`
  padding-right: 32px;
  color: #07064E;
  font-weight: bold;
  font-size: 13px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &.btn-dark {
    background-color: #07064E;
    color: #2BF5BA;
    border-radius: 20px;
    padding-right: 0;
    width: 170px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
   &.btn-light{
    background-color: #B2FFE8;
    color: #07064E;
    border-radius: 20px;
    padding-right: 0;
    width: 170px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left:24px;
  }

  a {
    cursor: pointer;
    text-decoration: none;
  }

  @media (max-width: 1160px) {
    padding-right: 17px;
    &:nth-of-type(8) {
      width: 156px;
    }
  }
`

class Navigation extends Component {
  state = {
    fontSizeDefault: "13px",
    delayMenu: "all .5s",
    isHomePage: true,
  }

  componentDidMount() {
    document.addEventListener("scroll", () => {
      const fontSize = window.scrollY < 90 ? "13px" : "12px"
      const transition = window.scrollY < 90 ? "all .5s" : "all .5s"
      this.setState({
        fontSizeDefault: fontSize,
        delayMenu: transition
      })
    })
    this.checkIsHomePage()
  }

  checkIsHomePage() {
    const   currentPathname = window?.location?.pathname || '';
    if (currentPathname !== '/') {
      this.setState({isHomePage: false})
    } else {
      this.setState({isHomePage: true})
    }
  }
  render() {
    const {isHomePage} = this.state;
    return (
      <NavigationWrapper>
        <NavigationList>

          <NavigationListItem
            style={{
              fontSize: `${this.state.fontSizeDefault}`,
              transition: `${this.state.delayMenu}`
            }}

            onClick={() =>  {
              if(!isHomePage) {
                navigate('/')
                setTimeout(() => {
                  scrollTo("#aboutSystem")
                }, 50)
              } else {
                  scrollTo("#aboutSystem")
              }
            }}
          >
            <Translate id="menu.about_system"/>
          </NavigationListItem>
          <NavigationListItem
            style={{
              fontSize: `${this.state.fontSizeDefault}`,
              transition: `${this.state.delayMenu}`
            }}
            onClick={() =>  {
              if(!isHomePage) {
                navigate('/')
                setTimeout(() => {
                  scrollTo("#forWhom")
                }, 50)
              } else {

                  scrollTo("#forWhom")

              }
              }}>
            <Translate id="menu.for_whom"/>
          </NavigationListItem>
          <NavigationListItem
            style={{
              fontSize: `${this.state.fontSizeDefault}`,
              transition: `${this.state.delayMenu}`
            }}

            onClick={() =>  {
              if(!isHomePage) {
                navigate('/')
                setTimeout(() => {
                  scrollTo("#security")
                }, 50)
              } else {

                  scrollTo("#security")

              }
            }}

          >
            <Translate id="menu.security"/>
          </NavigationListItem>
          <NavigationListItem
            style={{
              fontSize: `${this.state.fontSizeDefault}`,
              transition: `${this.state.delayMenu}`
            }}

            onClick={() =>  {
              if(!isHomePage) {
                navigate('/')
                setTimeout(() => {
                  scrollTo("#technology")
                }, 50)
              } else {

                  scrollTo("#technology")

              }
            }}

          >
            <Translate id="menu.technologies"/>
          </NavigationListItem>
          {/*<NavigationListItem*/}
          {/*  style={{*/}
          {/*    fontSize: `${this.state.fontSizeDefault}`,*/}
          {/*    transition: `${this.state.delayMenu}`*/}
          {/*  }}*/}
          {/*  onClick={() => scrollTo("#customers")}>*/}
          {/*  <Translate id="menu.customers"/>*/}
          {/*</NavigationListItem>*/}
          {/*<NavigationListItem*/}
          {/*  style={{*/}
          {/*    fontSize: `${this.state.fontSizeDefault}`,*/}
          {/*    transition: `${this.state.delayMenu}`*/}
          {/*  }}*/}
          {/*  onClick={() => scrollTo("#pricing")}>*/}
          {/*  <Translate id="menu.price_plan"/>*/}
          {/*</NavigationListItem>*/}
          <NavigationListItem as="a" target="_blank" href="https://pleogroup.notion.site/Wyborek-specyfikacja-6068d75b27214c6aae72c677e6149aed" className=""
            style={{
              fontSize: `${this.state.fontSizeDefault}`,
              transition: `${this.state.delayMenu}`
            }}>

            <Translate id="menu.specification"/>
          </NavigationListItem>

          <NavigationListItem
            style={{
              fontSize: `${this.state.fontSizeDefault}`,
              transition: `${this.state.delayMenu}`
            }}


            onClick={() =>  {
              if(!isHomePage) {
                navigate('/')
                setTimeout(() => {
                  scrollTo("#contact")
                }, 50)
              } else {

                  scrollTo("#contact")

              }
            }}

          >
            <Translate id="menu.contact"/>
          </NavigationListItem>

          {/*<NavigationListItem as={Link}*/}
          {/*                    style={{*/}
          {/*                      fontSize: `${this.state.fontSizeDefault}`,*/}
          {/*                      transition: `${this.state.delayMenu}`,*/}

          {/*                    }}>*/}

          {/*  <Link to="/regulamin">Regulamin</Link>*/}
          {/*</NavigationListItem>*/}




          <NavigationListItem as="a" href="https://admin.wyborek.pl/login" className="btn-dark"
            style={{
              fontSize: `${this.state.fontSizeDefault}`,
              transition: `${this.state.delayMenu}`
            }}>
            <Translate id="menu.sign_in"/>
          </NavigationListItem>


          <NavigationListItem as="a" href="https://admin.wyborek.pl/register" className="btn-light"
                              style={{
                                fontSize: `${this.state.fontSizeDefault}`,
                                transition: `${this.state.delayMenu}`
                              }}>
            <Translate id="menu.sign_up"/>
          </NavigationListItem>



          {/*<NavigationListItem*/}
          {/*  style={{*/}
          {/*    fontSize: `${this.state.fontSizeDefault}`,*/}
          {/*    transition: `${this.state.delayMenu}`*/}
          {/*  }}*/}

          {/*  onClick={() =>  {*/}
          {/*    if(!isHomePage) {*/}
          {/*      navigate('/')*/}
          {/*      setTimeout(() => {*/}
          {/*        scrollTo("#contact")*/}
          {/*      }, 50)*/}
          {/*    } else {*/}

          {/*      scrollTo("#contact")*/}

          {/*    }*/}
          {/*  }}*/}

          {/*>*/}
          {/*  <Translate id="menu.try_for_free"/>*/}
          {/*</NavigationListItem>*/}






          {/*<LanguageSwitcher/>*/}
        </NavigationList>
      </NavigationWrapper>
    )
  }
}

export default Navigation
