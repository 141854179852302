import React, { Component } from "react"
import styled from "styled-components"
import LogoSVG from "../images/logo.svg"
import Navigation from "./navigation"
import scrollTo from "gatsby-plugin-smoothscroll"
import LanguageSwitcher from "./languageSwitcher"
import { navigate } from "gatsby"
import { Translate } from "gatsby-plugin-translate"

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 50px 20px;
  z-index: 99999;
  display: block;
  img {
    height: 22px;
    cursor: pointer;
  }
  &.hidden {
    display: none;
  }
  @media ( min-width: 768px ) {
    img {
      height: 36px;
    }

    padding: 40px 56px;
  }
  @media ( min-width: 768px ) {
    padding: 30px 34px;
  }
`

const StyledNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    height: 40px;
    width: 104px;
    color: #2BF5BA;
    background: none;
    border: none;
    font-size: 13px;
    font-weight: 600;
  }

  @media (min-width: 765px) {
    input {
      background-color: #07064E;
      border-radius: 20px;
    }
  }

`

const MobileNavigation = styled.div`
  display: flex;
  @media (min-width: 1024px) {
    display: none;
  }
`

const ButtonsWrapper = styled.div`
display:flex;
justify-content: center;
align-items: center;
`

const NewStyledButton = styled.a`

font-size:13px;
padding:8px 24px 10px;
font-weight: 600;
text-align: center;
  @media (max-width:362px) {
    font-size: 10px!important;
  }
    &.btn-dark {
    background-color: #07064E;
    color: #2BF5BA;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 480px) {
     padding:8px 14px 10px;
    }
  }
  
   &.btn-light{
    background-color: #B2FFE8;
    color: #07064E;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left:12px;
        @media (max-width: 480px) {
     padding:8px 14px 10px;
         margin-left:8px;
    }
  }
`

class Header extends Component {
  state = {
    navBackground: "",
    delayMenu: "",
    heightLogoDefault: "",
    paddingMenu: "",
    isHomePage: true,
    isCalculatorPage: false,
  }

  componentDidMount() {
    document.addEventListener("scroll", () => {
      const backgroundColor = window.scrollY < 50 ? "" : "white"
      const transition = window.scrollY < 50 ? "all .5s" : "all .5s"
      const heightLogo = window.scrollY < 50 ? "" : "20px"
      const padding = window.scrollY < 50 ? "" : "20px"
      this.setState({
        navBackground: backgroundColor,
        delayMenu: transition, heightLogoDefault: heightLogo,
        paddingMenu: padding
      })
    })
    this.checkIsHomePage()
  }

  checkIsHomePage() {
    const currentPathname = window?.location?.pathname || '';
    if (currentPathname !== '/') {
      if (currentPathname === ( '/calculator/' || '/calculator' )) {
        this.setState({isCalculatorPage: true, isHomePage: false})
      } else {
        this.setState({isHomePage: false, isCalculatorPage: false})
      }
    } else {
      this.setState({isHomePage: true})
    }

  }

  render() {
    const {isHomePage, isCalculatorPage} = this.state;
    
    return (
      <StyledHeader className={isCalculatorPage ? 'hidden' : ''} style={{
        backgroundColor: `${this.state.navBackground}`,
        transition: `${this.state.delayMenu}`,
        padding: `${this.state.paddingMenu}`
      }}>
        <StyledNav>
          <img src={LogoSVG}
               onClick={() =>  {
                 if(!isHomePage) {
                   navigate('/')
                   setTimeout(() => {
                     scrollTo("#slider")
                   }, 50)
                 } else {
                   scrollTo("#slider")
                 }
               }}
               alt="Wyborek"
               title={"Wyborek"}
               style={{
                 height: `${this.state.heightLogoDefault}`,
                 transition: `${this.state.delayMenu}`
               }}
          />
          <div>
            <Navigation/>
            <MobileNavigation>

 <ButtonsWrapper>
   <NewStyledButton href="https://admin.wyborek.pl/login" className="btn-dark">
     <Translate id="menu.sign_in"/>
   </NewStyledButton>

   <NewStyledButton href="https://admin.wyborek.pl/login" className="btn-light">
     <Translate id="menu.sign_up"/>
   </NewStyledButton>
 </ButtonsWrapper>

              {/*<LanguageSwitcher/>*/}
            </MobileNavigation>
          </div>
        </StyledNav>
      </StyledHeader>
    )
  }
}

export default Header
