import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Header from "./header"
import "./layout.css"
import loadable from "@loadable/component"

const LiveChat = loadable(() => import("react-livechat"))

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
      query SiteTitleQuery {
          site {
              siteMetadata {
                  title
              }
          }
      }
  `)

  return (
    <>
      <Helmet
        title={data.site.siteMetadata.title}
        meta={[
          { name: "google-site-verification", content: "LghlcSuEpKhnmNJg9CxMxVR-fLv27XKcZZrKZO9W3mQ" }
        ]}
      />
      <Header />
      <main>{children}</main>
      <LiveChat license={10729697} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
