import React, { Component, useState } from "react"
import { LanguageSelector } from "gatsby-plugin-translate"
import styled from "styled-components"
import { func } from "prop-types"


const StyledLanguageSelector = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 20px;
  position: relative;

  a {
    text-decoration: none;
    color: #2BF5BA;
    font-weight: bold;
    font-size: 11px;
  }

  .toggleLanguage {
    transform: scaleY(1);
  }

  @media (max-width: 1160px) {
    margin-left: 10px;
  }
`

const StyledCurrentLanguage = styled.div`
  align-items: center;
  border-radius: 50%;
  background-color: #07064E;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30px;
  width: 30px;
  position: relative;
  text-transform: uppercase;
  z-index: 1;
  cursor: pointer;

  a {
    pointer-events: none;
  }

`
const StyledOtherLanguage = styled.div`
  background: transparent;
  border: 1px solid #07064E;
  border-radius: 0 0 15px 15px;
  box-sizing: border-box;
  height: 50px;
  margin: 0 auto;
  position: absolute;
  top: 19px;
  transform: scaleY(0);
  transform-origin: 0 0;
  transition: transform 0.3s ease-in-out 0s;
  width: 30px;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    color: #07064E;
  }
`

const LanguageSwitcher = () => {
  const [toggleState, setToggleState] = useState(false)
  const [change, setChange] = useState(true)

  function toggle() {
    setToggleState(!toggleState)
  }

  function changeLang() {
    setChange(!change)
  }

  return (
    <StyledLanguageSelector>
      <StyledCurrentLanguage onClick={toggle}>
        <LanguageSelector sourceLanguage={true}>PL</LanguageSelector>
      </StyledCurrentLanguage>
      <StyledOtherLanguage className={toggleState ? "toggleLanguage" : null} onClick={changeLang}>
        <LanguageSelector language={change ? "en" : "pl"}>{change ? "EN" : "PL"}</LanguageSelector>
      </StyledOtherLanguage>
    </StyledLanguageSelector>
  )
}

export default LanguageSwitcher
